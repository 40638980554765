<template>
  <div>
    <div>
      <div class="text-center">
        <div v-if="summ">данные на {{ summ.upload_date }}</div>
        <div>
          <select
            class="form-control form-control-sm shadow-none col-md-3 col-sm-2 d-inline m-1"
            v-model="exclude_working"
            @change="filter_change"
          >
            <option :value="true">не учитывать исправное и не гарантию</option>
            <option :value="false">учитывать исправное и не гарантию</option>
          </select>
        </div>
      </div>
      <div class="overflow-auto">
        <table class="table table-sm table-hover">
          <thead>
            <tr>
              <th colspan="5">
                <select
                  v-if="years"
                  class="form-control form-control-sm shadow-none col-md-2 col-sm-2 d-inline m-1"
                  v-model="STATS_FILTER.year"
                  @change="filter_change"
                >
                  <option
                    v-for="(item, idx) in years"
                    :key="idx + 'year'"
                    :value="item"
                    >{{ item }}</option
                  >
                </select>
                <button
                  href="#"
                  class="btn btn-sm btn-outline-primary shadow-none d-inline m-1"
                  @click="set_visible_stats_filter"
                >
                  фильтр
                </button>
                <button
                  v-if="STATS_VISIBLE_FILTER"
                  class="btn btn-sm btn-outline-success m-2 shadow-none m-1"
                  @click="filter_change"
                  :disabled="!STATS_APPLY_BTN_DISABLE"
                >
                  применить фильтр
                </button>
                <select
                  class="form-control form-control-sm shadow-none col-md-2 col-sm-2 d-inline m-1"
                  v-model="change_stats_url"
                  @change="filter_change"
                >
                  <option value="/stats/data_stats/get_data_stats_2.php"
                    >быстро</option
                  >
                  <option value="/stats/data_stats/get_data_stats.php"
                    >медленно</option
                  >
                </select>
                <span class="m-1" v-if="summ && summ.one_repair_price">{{
                  summ.one_repair_price
                }}</span>
                <button
                  v-if="USER_INFO.status === 'tehnik'"
                  class="btn btn-sm btn-outline-warning shadow-none m-1"
                  @click="logout"
                >
                  выход
                </button>
              </th>
              <th v-if="summ" class="text-center">
                {{ summ.summ_pcs_sale }} шт.
              </th>
              <th v-if="summ" class="text-center">
                {{ summ.summ_pcs_repair }} шт.
              </th>
              <th v-if="summ" class="text-center">
                {{ summ.percent === "nan" ? 0 : summ.percent }} %
              </th>
              <th v-if="summ && !STATS_EXCLUDE_WORKING" class="text-center">
                {{
                  summ.summ_pcs_no_guarantee === "nan"
                    ? 0
                    : summ.summ_pcs_no_guarantee
                }}
                шт.
              </th>
              <th v-if="summ && !STATS_EXCLUDE_WORKING" class="text-center">
                {{
                  summ.summ_pcs_no_defect === "nan"
                    ? 0
                    : summ.summ_pcs_no_defect
                }}
                шт.
              </th>
              <th v-if="summ" class="text-center">
                {{ summ.summ_zap }} &#8381;
              </th>
              <th v-if="summ" class="text-center">
                {{ summ.summ_rabot }} &#8381;
              </th>
              <th v-if="summ" class="text-center">{{ summ.summ }} &#8381;</th>
            </tr>
            <tr v-if="STATS_VISIBLE_FILTER">
              <td></td>
              <td>
                <input
                  class="form-control form-control-sm shadow-none"
                  type="text"
                  placeholder="наименование инструмента"
                  v-model="STATS_FILTER.other[0].value"
                  size="1"
                />
              </td>
              <td>
                <input
                  class="form-control form-control-sm shadow-none"
                  type="text"
                  placeholder="группа"
                  v-model="STATS_FILTER.other[1].value"
                  size="1"
                />
              </td>
              <td>
                <input
                  class="form-control form-control-sm shadow-none"
                  type="text"
                  placeholder="группа"
                  v-model="STATS_FILTER.other[2].value"
                  size="1"
                />
              </td>
              <td>
                <input
                  class="form-control form-control-sm shadow-none"
                  type="text"
                  placeholder="код"
                  v-model="STATS_FILTER.other[3].value"
                  size="1"
                />
              </td>
              <td>
                <input
                  class="form-control form-control-sm shadow-none"
                  type="text"
                  placeholder="больше"
                  v-model="STATS_FILTER.other[4].value"
                  size="1"
                />
              </td>
              <td></td>
              <td></td>
              <td v-if="!STATS_EXCLUDE_WORKING"></td>
              <td v-if="!STATS_EXCLUDE_WORKING"></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th></th>
              <th class="text-center cursor" @click="sort('name_instr')">
                наименование
              </th>
              <th class="text-center cursor" @click="sort('serialNumberGroup')">
                <div>группа</div>
                <div>ремонт</div>
              </th>
              <th class="text-center cursor" @click="sort('gruppa')">
                <div>группа</div>
                <div>продажи</div>
              </th>
              <th class="text-center cursor" @click="sort('cod')">код</th>
              <th class="text-center cursor" @click="sort('pcs_sale')">
                продано
              </th>
              <th class="text-center cursor" @click="sort('pcs_repair')">
                ремонт
              </th>
              <th class="text-center cursor" @click="sort('percent')">%</th>
              <th
                v-if="!STATS_EXCLUDE_WORKING"
                class="text-center cursor"
                @click="sort('pcs_no_guarantee')"
              >
                <div>не</div>
                <div>гарантия</div>
              </th>
              <th
                v-if="!STATS_EXCLUDE_WORKING"
                class="text-center cursor"
                @click="sort('pcs_no_defect')"
              >
                <div>нет</div>
                <div>дефекта</div>
              </th>
              <th class="text-center cursor" @click="sort('summ_zap')">зч</th>
              <th class="text-center cursor" @click="sort('summ_rabot')">
                работы
              </th>
              <th class="text-center cursor" @click="sort('summ')">сумма</th>
            </tr>
          </thead>
          <tbody v-if="list">
            <tr
              v-for="(item, idx) in list"
              :key="idx + 'tr'"
              @click="go_one_instr(item)"
              class="cursor"
              :class="item.repeat && 'text-primary'"
            >
              <td>{{ idx + 1 }}</td>
              <td>{{ item.name_instr }}</td>
              <td class="text-center">{{ item.serialNumberGroup }}</td>
              <td class="text-center">{{ item.gruppa }}</td>
              <td class="text-center">{{ item.cod }}</td>
              <td class="text-center">
                {{ item.pcs_sale }}{{ item.pcs_sale && " шт." }}
              </td>
              <td class="text-center">{{ item.pcs_repair }} шт.</td>
              <td class="text-center">
                <div class="small-text">
                  {{ `(${item.pcs_repair} / ${item.pcs_sale}) * 100` }}
                </div>
                {{ item.percent }} %
              </td>
              <td v-if="!STATS_EXCLUDE_WORKING" class="text-center">
                {{ item.pcs_no_guarantee }} шт.
              </td>
              <td v-if="!STATS_EXCLUDE_WORKING" class="text-center">
                {{ item.pcs_no_defect }} шт.
              </td>
              <td class="text-center">{{ item.summ_zap }} &#8381;</td>
              <td class="text-center">{{ item.summ_rabot }} &#8381;</td>
              <td class="text-center">{{ item.summ }} &#8381;</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div><Loader :percent="percent" v-if="loading" /></div>
  </div>
</template>
<script>
import { HTTPGETNOLOADERNOCOOCIE, HTTPGETNOLOADER } from "@/api";
import { mapMutations, mapGetters, mapActions } from "vuex";
import Loader from "@/components/Loader";
export default {
  name: "TableStats",
  components: { Loader },
  data: () => ({
    list: null,
    summ: null,
    loading: true,
    years: null,
    percent: null,
    interval: null
  }),
  computed: {
    ...mapGetters([
      "STATS_FILTER",
      "STATS_VISIBLE_FILTER",
      "STATS_APPLY_BTN_DISABLE",
      "USER_INFO",
      "STATS_PAGE_SCROLL",
      "STATS_EXCLUDE_WORKING",
      "STATS_URL"
    ]),
    exclude_working: {
      get() {
        return this.STATS_EXCLUDE_WORKING;
      },
      set(value) {
        this.set_stats_exclude_working(value);
      }
    },
    change_stats_url: {
      get() {
        return this.STATS_URL;
      },
      set(value) {
        this.set_stats_url(value);
      }
    }
  },
  watch: {
    STATS_FILTER: {
      handler() {
        this.set_filter_apply_btn_disable(true);
      },
      deep: true
    },
    STATS_VISIBLE_FILTER: function() {
      if (!this.STATS_VISIBLE_FILTER) {
        this.STATS_FILTER.other.map(item => {
          item.value = "";
        });
        this.get_data();
      } else {
        this.set_filter_apply_btn_disable(false);
      }
    }
  },
  mounted() {
    this.get_data();
  },
  methods: {
    ...mapActions(["LOGOUT", "LOADERONOFF"]),
    ...mapMutations([
      "set_stats_filter",
      "set_visible_stats_filter",
      "set_filter_apply_btn_disable",
      "set_stats_sort",
      "set_stats_page_scroll",
      "set_stats_exclude_working",
      "set_stats_url"
    ]),
    get_data() {
      this.loading = true;
      this.percent = 0;
      this.get_loading_percent();
      HTTPGETNOLOADER({
        url: this.STATS_URL,
        params: {
          filter: this.STATS_FILTER,
          exclude_working: this.STATS_EXCLUDE_WORKING
        }
      })
        .then(response => {
          this.list = response.data.group_repairs_list;
          this.summ = response.data.summ;
          this.years = response.data.years;
        })
        .then(() => this.set_filter_apply_btn_disable(false))
        .then(() => window.scrollBy(0, this.STATS_PAGE_SCROLL))
        .then(() => this.set_stats_page_scroll(0))
        .then(() => clearInterval(this.interval))
        .then(() => (this.loading = false));
    },
    filter_change() {
      this.get_data();
    },
    logout() {
      this.LOGOUT();
      this.$router.push("/login");
    },
    sort(field) {
      if (this.STATS_FILTER.sort.field === field) {
        this.set_stats_sort({
          field: field,
          value: !this.STATS_FILTER.sort.sort
        });
      } else {
        this.set_stats_sort({ field: field, value: false });
      }
      this.get_data();
    },
    go_one_instr(item) {
      // this.$router.push(
      //   `/kabinet/stats/one-instr?data=${encodeURIComponent(
      //     JSON.stringify(item)
      //   )}`
      // );
      let routeData = this.$router.resolve({
        name: "OneInstr",
        query: { data: JSON.stringify(item) }
      });
      window.open(routeData.href, "_blank");
      this.set_stats_page_scroll(window.pageYOffset);
    },
    get_loading_percent() {
      this.interval = setInterval(() => {
        HTTPGETNOLOADERNOCOOCIE({
          url: "/stats/data_stats/get_percent.php"
        }).then(response => (this.percent = response.data));
      }, 1000);
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
}
.text-center {
  vertical-align: middle;
}
input {
  font-size: 0.9em;
}
.cursor {
  cursor: pointer;
}
.small-text {
  font-size: 6pt;
}
</style>
