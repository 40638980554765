<template>
  <div class="wrapper">
    <div>
      <UploadProdaji v-if="USER_INFO.status === 'admin'" @update="update" />
    </div>
    <div>
      <TableStats :key="key" />
    </div>
  </div>
</template>
<script>
import UploadProdaji from "./UploadProdaji.vue";
import { mapGetters } from "vuex";
import TableStats from "./TableStats.vue";
export default {
  name: "Stats",
  components: {
    UploadProdaji,
    TableStats
  },
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: () => ({
    key: 0
  }),
  methods: {
    update() {
      this.key += 1;
    }
  }
};
</script>
<style scoped>
.wrapper {
  margin: 10px;
}
</style>
