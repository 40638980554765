<template>
  <div>
    <button
      class="btn btn-sm btn-outline-primary shadow-none m-1"
      @click="open_input"
    >
      импорт данных о продажах из файла
    </button>
    <button
      v-if="STATS_URL === '/stats/data_stats/get_data_stats_2.php'"
      class="btn btn-sm btn-outline-success shadow-none m-1"
      @click="update_create_stats"
    >
      обновить
    </button>
    <input type="file" ref="file" id="file" @change="add_file" />
    <div><Loader :percent="false" v-if="loading" /></div>
  </div>
</template>
<script>
import { HTTPPOSTFILE, HTTPGET } from "@/api";
import { mapGetters } from "vuex";
import Loader from "@/components/Loader";
export default {
  name: "UploadProdaji",
  components: {
    Loader
  },
  computed: {
    ...mapGetters(["STATS_URL"])
  },
  data: () => ({
    loading: false
  }),
  methods: {
    open_input() {
      this.$refs.file.click();
    },
    add_file() {
      let formData = new FormData();
      formData.append(`file`, this.$refs.file.files[0]);
      HTTPPOSTFILE({
        url: "/stats/upload_data/upload_prodaji.php",
        data: formData
      }).then(response => {
        if (response.data.error) {
          alert(`${response.data.error}`);
          this.$refs.file.value = "";
        } else {
          this.$refs.file.value = "";
          this.$emit("update");
        }
      });
    },
    update_create_stats() {
      this.loading = true;
      HTTPGET({
        url: "/stats/data_stats/update_create_stats.php"
      })
        .then(() => (this.loading = false))
        .then(() => this.$emit("update"));
    }
  }
};
</script>
<style scoped>
#file {
  position: absolute;
  top: -1000px;
  left: -1000px;
}
</style>
